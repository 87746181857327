import Dialog from "components/dist/atoms/Dialog";
import Icon from "components/dist/atoms/Icon";
import Popover from "components/dist/atoms/Popover";
import Skeleton from "components/dist/atoms/Skeleton";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import BellNotificationComp from "components/dist/organisms/BellNotification";
import { classNames } from "src/utils/tailwind-class-names";

import { useBellNotificationState } from "./bell-notification.state";
import { BellNotificationListItem } from "./bell-notification-list-item.component";

export const BellNotification = () => {
    const state = useBellNotificationState();

    const content = <BellNotificationComp>
        <BellNotificationComp.Header
            onBackClick={() => state.onPopoverOpenChange(false)}
            onMarkAllAsRead={state.onMarkAllAsRead}
            totalUnread={state.totalUnread}
        />
        <BellNotificationComp.Filters
            onFilterChange={state.setActiveFilter}
            activeFilter={state.activeFilter}
            onMarkAllAsRead={state.onMarkAllAsRead}
            totalUnread={state.totalUnread}
            totalUnreadAssignedToMe={state.totalUnreadAssignedToMe}
            activeTab={state.activeTab}
            onTabChange={state.setActiveTab}
        />
        {state.notifications.length === 0 && !state.isLoading ? (<BellNotificationComp.Empty />) : null}
        {(state.notifications.length > 0 || state.isLoading) && <BellNotificationComp.List>
            {Object.keys(state.groupedNotifications)
                .map((notification: 'today' | 'yesterday' | 'older') => (
                    <div key={notification}>
                        {state.groupedNotifications[notification].length > 0 && <Text className='sticky top-0 bg-white pb-2 z-10 px-3 pt-5' weight='medium' size='sm'>{GroupTitle[notification]}</Text>}
                        {state.groupedNotifications[notification].map(notification => (
                            <BellNotificationListItem
                                hasLink={!!notification.link}
                                onRead={state.onNotificationRead}
                                user={notification.user}
                                onClick={state.onNotificationClick}
                                id={notification.id}
                                dateTimeNotification={notification.dateTimeNotification}
                                isRead={notification.isRead}
                                key={notification.id}
                                title={notification.content}
                                itemTitles={notification.items ?? []}
                                loanTitle={notification.loanTitle}
                                loanId={notification.loanShortCode}
                                threadTitle={notification.threadTitle}
                                messagePreview={notification.messagePreview}
                            />
                        ))}
                    </div>
                ))}
            <div ref={state.inViewReference} className="h-1 shrink-0 w-full" />
            {state.isLoading ? (<Stack space='lg' className="px-3 py-5">
                {[1, 2, 3, 4, 5, 6].map((_, index) => (<Skeleton className="h-24 w-full" key={index} />))}
            </Stack>) : null}
        </BellNotificationComp.List>}
    </BellNotificationComp>

    if (state.isMobile) {
        return <Dialog
            open={state.isPopoverOpen}
            onOpenChange={state.onPopoverOpenChange}
        >
            <Dialog.Trigger className="px-2">
                <Icon
                    name={state.totalUnread > 0 ? "BellNotification" : "Bell"}
                    width={20}
                    height={20}
                    className={classNames('overflow-visible', {
                        "text-blue-100 [&>_path:nth-child(2)]:text-yellow-60 [&>_path:nth-child(1)]:origin-top [&>_path:nth-child(1)]:will-change [&>_path:nth-child(1)]:animate-bell [&>_path:nth-child(2)]:fill-yellow-60": state.totalUnread > 0
                    })}
                    strokeWidth={1.5} />
            </Dialog.Trigger>
            <Dialog.Content className="w-screen h-screen">
                {content}
            </Dialog.Content>
        </Dialog>
    }
    return <Popover
        open={state.isPopoverOpen}
        onOpenChange={state.onPopoverOpenChange}
    >
        <Popover.Trigger>
            <Icon
                name={state.totalUnread > 0 ? "BellNotification" : "Bell"}
                width={20}
                height={20}
                className={classNames('overflow-visible', {
                    "text-blue-100 [&>_path:nth-child(2)]:text-yellow-60 [&>_path:nth-child(1)]:origin-top [&>_path:nth-child(1)]:will-change [&>_path:nth-child(1)]:animate-bell [&>_path:nth-child(2)]:fill-yellow-60": state.totalUnread > 0
                })}
                strokeWidth={1.5} />
        </Popover.Trigger>
        <Popover.Portal>
            <Popover.Content
                align="end"
                className="p-0 w- shadow-0 bg-transparent border-none z-full-screen">
                {content}
            </Popover.Content>
        </Popover.Portal>
    </Popover>
};

const GroupTitle = ({
    today: "Today",
    yesterday: "Yesterday",
    older: "Older"
}) as const;