import { MessageDtoExtended } from "src/services/messageApi";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

export const useBorrowerPortalMessagesSearchResultsList = () => {
    const getRowTitle = (mode: string, row: MessageDtoExtended) => {
        return mode === "THREAD" ? getUserDisplayName(row.from) : (row.messageThread?.title || row.subject || "(No Subject)")
    }

    return {
        getRowTitle,
    } as const;
};