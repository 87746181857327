import Button from 'components/dist/atoms/Button';
import DateTimeDisplay from 'components/dist/atoms/DateTimeDisplay';
import HighlightText from 'components/dist/atoms/HighlightText';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import { AppUserDTO2, MessageDto, Role, RoleLenderGroupLevel } from 'src/backend';
import { MysherpasTooltip } from 'src/components/common/mysherps-tooltip';
import { RenderIf } from 'src/components/common/render-if';
import { AttachmentDocument } from 'src/components/messages/attachment-document';
import { stopPropagation } from 'src/utils/dom/stop-propagation';
import { classNames } from 'src/utils/tailwind-class-names';

import { BorrowerPortalMessagesThreadUsers } from '../../borrower-portal-messages-thread/borrower-portal-messages-thread-users.component';

interface BorrowerPortalMessagesThreadProps {
    users: AppUserDTO2[];
    unread: boolean;
    important: boolean;
    highlightText?: string;
    name: string;
    preview: string;
    title: string;
    date: string;
    loggedInUserId: string;
    documents: string[];
    onMessageClick: () => void;
    loggedRoleGroup: RoleLenderGroupLevel;
    onDeleteDraft?: () => void;
    generationType: MessageDto['generationType'];
    className?: string;
    isDraft?: boolean;
}

export const BorrowerPortalMessagesSearchResultsListItem = (props: BorrowerPortalMessagesThreadProps) => {

    return <Stack
        asChild
        space="sm"
        row
        className={classNames('p-3 bg-white border group border-gray-neutral-80 rounded-md cursor-pointer hover:bg-blue-10', props.className)}
        items="stretch">
        <li>
            <div className='h-full'>
                <BorrowerPortalMessagesThreadUsers
                    loggedInUserId={props.loggedInUserId}
                    users={props.users}
                    isDraft={props.isDraft}
                    loggedRoleGroup={props.loggedRoleGroup}
                    inactive={false}
                    // TODO: handle threadMembersType
                    threadMembersType={undefined}
                />
            </div>
            <Stack className='flex-1 overflow-hidden'
                onClick={props.onMessageClick}
                space="sm" justify='start'>
                <Stack className='flex-1 overflow-hidden' row space="sm" items="center">
                    <Text truncate size="sm" weight={props.unread ? "medium" : "normal"}>
                        <HighlightText text={props.title} highlightText={props.highlightText} />
                    </Text>
                    {props.isDraft && (
                        <Text variant="secondary" truncate size="sm" weight={props.unread ? "medium" : "normal"}>
                            (Draft)
                        </Text>
                    )}
                </Stack>
                <Stack row space="sm" items="center">
                    <RenderIf condition={!!props.preview}>
                        <Text variant={props.isDraft ? "secondary" : "default"} size="sm" truncate as="div" weight={props.unread ? "medium" : "normal"}>
                            {props.name ? `${props.isDraft ? "You" : props.name}: ` : ""}
                            <HighlightText
                                text={props.preview}
                                highlightText={props.highlightText}
                            />
                        </Text>
                    </RenderIf>
                    <RenderIf condition={!props.preview}>
                        <Text variant={props.isDraft ? "secondary" : "default"} size="sm" truncate as="div" weight={props.unread ? "medium" : "normal"}>No message </Text>
                    </RenderIf>
                    <RenderIf condition={props.important}>
                        <MysherpasTooltip title="Important">
                            <div>
                                <Icon name="LabelSolid" width={16} height={16} strokeWidth={1.5} />
                            </div>
                        </MysherpasTooltip>
                    </RenderIf>
                </Stack>
                {props.documents.length > 0 && <Stack row className='' wrap space="sm">
                    {props.documents.map((documentId) => <AttachmentDocument
                        key={documentId}
                        highlightText={props.highlightText}
                        id={documentId}
                    />)}
                </Stack>}
            </Stack>
            <Stack row space="sm" justify="end" items="start" className='group-hover:hidden group-focus-within:hidden'>
                <Text size="xs">
                    <DateTimeDisplay
                        type="DATE"
                        dateTime={props.date} />
                </Text>
                {props.generationType === "EMAIL" && <MysherpasTooltip title="Email message">
                    <div>
                        <Icon name="Mail" width={16} height={16} strokeWidth={1.5} />
                    </div>
                </MysherpasTooltip>}
                {props.generationType === "IN_APP" && <MysherpasTooltip title="In-app message">
                    <div>
                        <Icon name="MessageText" width={16} height={16} strokeWidth={1.5} />
                    </div>
                </MysherpasTooltip>}
            </Stack>
            {props.isDraft && props.onDeleteDraft && <ScreenSizeDropdownMenu>
                <ScreenSizeDropdownMenu.Trigger asChild>
                    <Button size="sm" variant='ghost' circle className='self-center lg:hidden group-hover:inline-flex group-focus-within:inline-flex'>
                        <Icon name="MoreVert" width={16} height={16} strokeWidth={1.5} />
                    </Button>
                </ScreenSizeDropdownMenu.Trigger>
                <ScreenSizeDropdownMenu.Content
                    onClick={stopPropagation}
                    align='end'
                    side='bottom'
                    className='lg:w-72 w-full max-w-full p-2'>
                    <ScreenSizeDropdownMenu.Item className='flex gap-2 items-center'
                        onSelect={props.onDeleteDraft}>
                        <Icon name="Trash" width={16} height={16} strokeWidth={1.5} /> Delete
                    </ScreenSizeDropdownMenu.Item>
                </ScreenSizeDropdownMenu.Content>
            </ScreenSizeDropdownMenu>}
        </li>
    </Stack>
};