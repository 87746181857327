import { useRouter } from 'next/router';
import { QUERY_PARAM_LOAN_ID } from 'src/constants/query-params';
import { QUERY_PARAM_LENDER_ID } from 'src/constants/url';
import { useGetDocumentWithDownloadUrlQuery } from 'src/services/documentApi';

import { AttachmentDocumentProps } from './attachment-document.types';


export const useAttachmentDocument = (props: AttachmentDocumentProps) => {
    const router = useRouter();
    const { data, isLoading, error } = useGetDocumentWithDownloadUrlQuery({
        id: props.id
    });

    const lenderId = router.query[QUERY_PARAM_LENDER_ID] as string;

    const loanId = router.query[QUERY_PARAM_LOAN_ID] as string;

    return {
        document: data,
        isLoading,
        lenderId,
        loanId,
        query: router.query,
        pathname: router.pathname,
        asPath: router.asPath,
        error
    }
}