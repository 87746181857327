import Button from 'components/dist/atoms/Button';
import DropdownMenu from 'components/dist/atoms/DropdownMenu';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import { classNames } from 'src/utils/tailwind-class-names';

import { useBorrowerPortalMessagesSearchResultsHeaderState } from './borrower-portal-messages-search-results-header.state';

const dropDownItemLabel = {
    'DESC': 'Most Recent',
    'ASC': 'Oldest',
}

const dropdownMenuItems = [
    { label: dropDownItemLabel['DESC'], value: 'DESC' as const },
    { label: dropDownItemLabel['ASC'], value: 'ASC' as const },
];

export const BorrowerPortalMessagesSearchResultsHeader = () => {
    const state = useBorrowerPortalMessagesSearchResultsHeaderState();

    return <Stack className='container container-tablet flex-col lg:flex-row gap-4 lg:gap-6'>
        <Text
            className="flex-1"
            as="div"
            size="sm">
            <Text weight="medium" as="strong" size="inherit">Messages:</Text> {state.headerText}
        </Text>
        <Stack
            className={classNames({
                'hidden': !state.filterQuery,
            })}
            row
            space="md">
            <ScreenSizeDropdownMenu modal={false}>
                <ScreenSizeDropdownMenu.Trigger asChild>
                    <Button size="sm" variant="secondary" className='border bg-gray-neutral-30 border-neutral-gray-80 gap-1'>
                        {dropDownItemLabel[state.filterOrder]} <Icon name="NavArrowDown" width={16} height={16} strokeWidth={1.5} />
                    </Button>
                </ScreenSizeDropdownMenu.Trigger>
                <ScreenSizeDropdownMenu.Content
                    align='start'
                    side='bottom'
                    alignOffset={0}
                    className='lg:w-72 max-w-full p-4 lg:p-2'>
                    {dropdownMenuItems.map(item => (<ScreenSizeDropdownMenu.Item
                        className={
                            classNames('w-full', {
                                'bg-blue-10': state.filterOrder === item.value,
                            })
                        }
                        key={item.value}
                        onSelect={() => state.onSortChange(item.value)} asChild>
                        <Stack space="sm" row className='flex-1 text-left'>
                            <Text size="sm" className="flex-1">{item.label}</Text>
                            {state.filterOrder === item.value && <Icon name="Check" width={20} height={20} strokeWidth={1.5} className="text-blue-100" />}
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>))}
                </ScreenSizeDropdownMenu.Content>
            </ScreenSizeDropdownMenu>
            <Button
                onClick={state.onClearSearch}
                size="sm" variant="outline" className='text-blue-100'>
                Clear <Icon name="Cancel" width={16} height={16} strokeWidth={1.5} />
            </Button>
        </Stack>
    </Stack>
}