import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';

import { useBorrowerPortalMessagesSearchResultsState } from './borrower-portal-messages-search-results.state';
import { BorrowerPortalMessagesSearchResultsHeader } from './borrower-portal-messages-search-results-header';
import { BorrowerPortalMessagesSearchResultsList } from './borrower-portal-messages-search-results-list';

export const BorrowerPortalMessagesSearchResults = () => {
    const state = useBorrowerPortalMessagesSearchResultsState();

    return <>
        <BorrowerPortalMessagesSearchResultsHeader />
        {!state.isLoading && state.rows.length === 0 && <Stack
            items="center"
            justify='center'
            space="md" className='flex-1'>
            <Stack space="xl" justify='center' items="center">
                <Icon name="AppInterfaceMessage" width={63} height={62} strokeWidth={1.5} />
                <Text size="sm">
                    No results match your search
                </Text>
            </Stack>
        </Stack>}
        {state.isLoading && <Stack
            items="center"
            justify='center'
            space="md" className='flex-1'>
            <Stack space="xl" justify='center' items="center">
                <div className='w-10 h-10 aspect-square border-4 border-t-transparent animate-spin border-blue-100 rounded-full' />
                <Text size="sm">
                    Searching...
                </Text>
            </Stack>

        </Stack>}
        {!state.isLoading && state.rows.length > 0 && <BorrowerPortalMessagesSearchResultsList
            onNext={state.next}
            messages={state.rows}
            hasMore={state.hasMore}
            mode={state.mode}
            filterQuery={state.filterQuery}
            loggedRoleGroup={state.loggedRoleGroup}
            loggedInUserId={state.loggedInUserId}
        />}
    </>
}