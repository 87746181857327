import HighlightText from 'components/dist/atoms/HighlightText';
import Skeleton from 'components/dist/atoms/Skeleton';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import FileIcon from 'components/dist/molecules/FileIcon';
import { NextLinkComposed } from 'src/components/common/next-link-composed';
import { QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID } from 'src/constants/form-element';
import { QUERY_PARAM_LOAN_ID } from 'src/constants/query-params';
import { QUERY_DOCUMENT_PREVIEW_ID, QUERY_PARAM_VIEWER_ACTIONS_DISABLED } from 'src/constants/shoebox';

import { useAttachmentDocument } from './attachment-document.state';
import { AttachmentDocumentProps } from './attachment-document.types';

export const AttachmentDocument = (props: AttachmentDocumentProps) => {
    const state = useAttachmentDocument(props);
    // show loading skeleton
    if (state.isLoading) return <Skeleton className='w-48 h-12' />
    // show error message
    if (state.error) return <Text size="sm" className='text-red-60'>Error loading attachment</Text>

    // show attachment
    return <Stack
        row
        className='bg-gray-neutral-30 pl-3 py-2 pr-6'
        items="center"
        space="xs" asChild>
        <NextLinkComposed
            // linkAs={state.asPath}
            to={{
                pathname: state.pathname,
                query: {
                    ...state.query,
                    [QUERY_PARAM_LOAN_ID]: state.loanId,
                    [QUERY_DOCUMENT_PREVIEW_ID]: state.document.id,
                    // [QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID]: state.document.id,
                    [QUERY_PARAM_VIEWER_ACTIONS_DISABLED]: 1
                }
            }}>
            <FileIcon fileName={state.document.name} />
            <Text truncate size="xss">
                <HighlightText
                    text={state.document.name}
                    highlightText={props.highlightText}
                />
            </Text>
        </NextLinkComposed>
    </Stack>
}