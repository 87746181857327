import Avatar from 'components/dist/atoms/Avatar';
import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import ScreenSizeTooltip from 'components/dist/molecules/ScreenSizeTooltip';
import UserNameText from 'components/dist/molecules/UserNameText';
import { Fragment } from 'react';
import { AppUserDTO2, MessageThreadMemberType, RoleLenderGroupLevel } from 'src/backend';
import { RenderIf } from 'src/components/common/render-if';
import { classNames } from 'src/utils/tailwind-class-names';

export interface BorrowerPortalMessagesThreadProps {
    users: AppUserDTO2[];
    loggedInUserId: string;
    loggedRoleGroup: RoleLenderGroupLevel;
    isDraft?: boolean;
    inactive: boolean;
    threadMembersType: MessageThreadMemberType;
}

export const BorrowerPortalMessagesThreadUsers = (props: BorrowerPortalMessagesThreadProps) => {
    const lenderTeam = props.users?.filter(user => user.loggedRoleGroup === 'LENDER');
    const borrowerTeam = props.users?.filter(user => user.loggedRoleGroup === "BORROWER");
    const contactTeam = props.users?.filter(user => user.loggedRoleGroup === "CONTACT");
    const isLoggedInUserLender = !['CONTACT', 'BORROWER'].includes(props.loggedRoleGroup);
    const teams = []

    if (isLoggedInUserLender) {
        teams.push({ title: 'My Team', users: lenderTeam });
        teams.push({ title: 'Borrowing Team', users: borrowerTeam });
        teams.push({ title: 'Contact Team', users: contactTeam });
    } else {
        teams.push({ title: 'My Team', users: borrowerTeam });
        teams.push({
            title: 'Lending Team', users: lenderTeam
        });
        teams.push({ title: 'Contact Team', users: contactTeam });
    }
    const avatarStyleMap = {
        ONLY_LENDER: {
            borderColor: 'border-blue-100',
            backgroundColor: 'bg-blue-100',
            iconColor: 'white'
        },
        ONLY_BORROWER: {
            borderColor: 'border-yellow-60',
            backgroundColor: 'bg-yellow-60',
            iconColor: 'black'
        },
        MIXED: {
            borderColor: 'border-black-dark',
            backgroundColor: '',
            iconColor: ''
        },
        INACTIVE: {
            borderColor: 'border-gray-neutral-70',
            backgroundColor: 'bg-gray-neutral-30',
            iconColor: 'text-gray-neutral-70'
        }
    };
    const avatarStyleType = props.inactive ? "INACTIVE" : props.threadMembersType;
    const currentStyle = avatarStyleMap[avatarStyleType] || {
        borderColor: 'border-black-dark',
        backgroundColor: '',
        iconColor: 'black'
    };

    return <ScreenSizeTooltip>
        <ScreenSizeTooltip.Trigger asChild>
            <Stack>
                <RenderIf condition={props.isDraft}>
                    <Avatar className='bg-white border border-black-dark'
                        size="xs">
                        <Avatar.Fallback className='bg-white'>
                            <Icon name="DocumentEmpty" width={16} height={16} strokeWidth={1.5} />
                        </Avatar.Fallback>
                    </Avatar>
                </RenderIf>
                <RenderIf condition={!props.isDraft}>
                    <Avatar className={`${currentStyle.borderColor} border`} size="xs">
                        <Avatar.Fallback className={classNames(currentStyle.backgroundColor, currentStyle.iconColor)}>
                            <Icon name="GroupPeople" width={12} height={12} strokeWidth={1.5} color={currentStyle.iconColor} />
                        </Avatar.Fallback>
                    </Avatar>
                </RenderIf>
            </Stack>
        </ScreenSizeTooltip.Trigger>
        <ScreenSizeTooltip.Content
            align="start"
            side="bottom"
            alignOffset={0}
            className="max-w-xs p-4 lg:p-2 rounded"
        >
            <Stack space="sm">
                {teams
                    .filter(team => team.users?.length > 0)
                    .map((team, index) => <Fragment key={team.title}>
                        {[1, 2].includes(index) && <Separator />}
                        <Stack key={team.title} space="sm">
                            <Text size="xs" weight="medium">
                                {team.title}
                            </Text>
                            <Stack space="sm">
                                {team.users.map(user => <UserNameText
                                    key={user.id}
                                    isMe={user.id === props.loggedInUserId}
                                    user={user}
                                    size="xs" />)}
                            </Stack>
                        </Stack>
                    </Fragment>)}
            </Stack>
        </ScreenSizeTooltip.Content>
    </ScreenSizeTooltip>
};