import { BasicLoanDto, MessageThreadDto } from "src/backend";
import { useMessagesContext } from "src/contexts/messages-context";


export const useBorrowerPortalMessagesSearchResultsHeaderState = () => {
    const messagesContext = useMessagesContext();

    const onSortChange = (direction: 'DESC' | 'ASC') => {
        messagesContext.onFilterOrderChange(direction);
    };

    const onClearSearch = () => {
        messagesContext.onFilterQueryChange('', true);
        messagesContext.onFilterLabelChange(null);
    }
    return {
        onClearSearch,
        searchLevel: messagesContext.activeThread ? 'messages' : 'threads',
        onFilterQueryChange: messagesContext.onFilterQueryChange,
        onSortChange,
        filterOrder: messagesContext.filterOrder,
        filterQuery: messagesContext.filterQuery,
        headerText: getHeaderText(messagesContext.loan, messagesContext.activeThread),
    } as const;
}

const getHeaderText = (loan: Pick<BasicLoanDto, "projectName">, thread: MessageThreadDto) => {
    // if we have a thread, we are in a thread view
    if (thread) {
        return `Results in ${thread.title} messages`;
    } else if (loan) {
        return `Results ${loan.projectName} messages`;
    }
    return 'Results in messages';
}